import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import ContentElement from 'components/ContentElement';
import { SliderVariant } from 'components/Slider';
import Carousel from 'containers/Carousel';
import FaqSection from 'containers/FaqSection/FaqSection';

import sortArr from 'utils/sortArr';

const OurBrandsDetailPage: FC<OurBrandsDetailPageTypes.OurBrandsDetailProps> = ({
  data: {
    ourBrandsDetail: {
      urls,
      seo,
      bannerWithoutCropp,
      contentElement,
      internalNavHeading,
      articleCarousel,
      productCarousel,
      faqSection,
      firstHeadingH1,
    },
    allProductDetail: { productsList },
    allArticleDetail: { articlesList },
  },
}) => {
  const { title, keywords, description } = seo;

  const { ARTICLES, PRODUCTS } = SliderVariant;

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {bannerWithoutCropp?.imageBannerDesktop || bannerWithoutCropp?.imageBannerMobile ? (
        <Banner
          imageBannerDesktop={bannerWithoutCropp.imageBannerDesktop}
          imageBannerTablet={bannerWithoutCropp.imageBannerTablet}
          imageBannerMobile={bannerWithoutCropp.imageBannerMobile}
          {...bannerWithoutCropp.banner[0]}
        />
      ) : null}
      <ContentElement
        firstHeadingH1={Boolean(firstHeadingH1)}
        showNav
        contentElement={contentElement}
        navHeading={internalNavHeading}
      />
      {productsList.length && productCarousel ? (
        <Carousel
          carouselItems={productsList}
          carouselVariant={PRODUCTS}
          carousel={productCarousel}
        />
      ) : null}
      {articlesList.length && articleCarousel ? (
        <Carousel
          carouselItems={sortArr(articlesList, 'articleCardOrder')}
          carouselVariant={ARTICLES}
          carousel={articleCarousel}
        />
      ) : null}
      {faqSection?.accordionItem ? (
        <FaqSection
          items={faqSection.accordionItem}
          heading={faqSection.title}
          subheading={faqSection.description}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String], $productsUrls: [String], $articlesUrls: [String]) {
    ourBrandsDetail(lang: { eq: $lang }, url: { in: $path }) {
      firstHeadingH1
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      bannerWithoutCropp {
        banner {
          ...BannerFragment
        }
        imageBannerDesktop {
          alt
          imageData {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        imageBannerMobile {
          alt
          imageData {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        imageBannerTablet {
          alt
          imageData {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      internalNavHeading
      contentElement {
        ...ContentElementFragment
      }
      articleCarousel {
        ...CarouselFragment
      }
      productCarousel {
        ...CarouselFragment
      }
      faqSection {
        ...FaqSectionFragment
      }
    }
    allProductDetail(filter: { url: { in: $productsUrls } }) {
      productsList: nodes {
        ...ProductCardFragment
      }
    }
    allArticleDetail(filter: { url: { in: $articlesUrls } }) {
      articlesList: nodes {
        ...ArticleCardFragment
        articleCardOrder
      }
    }
  }
`;

export default OurBrandsDetailPage;
