import React, { FC, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Typography from 'common/Typography';
import Button from 'components/Button';

import { toggleState } from 'utils/toggleState';

import { AccordionMappedVariantType, AccordionProps } from './models.d';

import './Accordion.scss';

const Accordion: FC<AccordionProps> = ({
  visibleContent,
  hiddenContent,
  variant,
  iconSize,
  className,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isMenuVariant = variant === 'menu';

  const accordionClasses = classNames('accordion', className, {
    [`accordion--${variant}`]: variant,
    [`accordion--icon-${iconSize}`]: iconSize,
    'accordion--open': isOpen,
  });

  const accordionButtonClasses = classNames('accordion__button', {
    'total-disabled--child': !isOpen,
  });
  const accordionHiddenClasses = classNames('accordion__hidden-content');

  const buttonUi: AccordionMappedVariantType = {
    menu: {
      variant: 'menu',
    },
    bold: {
      variant: 'accordion',
      typography: { weight: 'bold', color: 'black', size: 20 },
    },
    default: {
      variant: 'accordion',
      typography: { weight: 'bold', color: 'sherwood-green', size: { base: 16, md: 18 } },
    },
  };

  const renderedAccordion = (
    <>
      <Button
        ui={buttonUi[variant]}
        className={accordionButtonClasses}
        onClick={() => toggleState(setIsOpen)}
      >
        {visibleContent ? (
          <Typography
            dangerouslySetInnerHTML={visibleContent.toString()}
            className="accordion__show-text"
          />
        ) : null}
        {isMenuVariant ? (
          <div className="accordion__icon" />
        ) : (
          <FontAwesomeIcon className="accordion__icon" icon={faPlus} size="sm" />
        )}
      </Button>
      <div className={accordionHiddenClasses}>
        {isMenuVariant ? (
          hiddenContent
        ) : (
          <div className="accordion__description">
            {typeof hiddenContent === 'string' ? (
              <Typography
                as="div"
                dangerouslySetInnerHTML={hiddenContent}
                size={buttonUi[variant].typography?.size}
                color="grey"
              />
            ) : (
              hiddenContent
            )}
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className={accordionClasses} data-testid="accordion-item">
      {renderedAccordion}
    </div>
  );
};

export default Accordion;
