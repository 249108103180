import { InternalButtonUIType } from 'components/Button';

type AccordionIconSize = 'big' | 'small';

export enum AccordionVariant {
  DEFAULT = 'default',
  BOLD = 'bold',
  MENU = 'menu',
}

export interface AccordionProps {
  visibleContent: string | React.ReactNode;
  hiddenContent: string | React.ReactNode;
  variant: AccordionVariant;
  iconSize?: AccordionIconSize;
  className?: string;
}

type AccordionType = {
  title: string;
  description: string;
  variant: AccordionVariant;
};

export type AccordionMappedVariantType = {
  [variant in AccordionVariant]: InternalButtonUIType;
};
