import React, { FC } from 'react';
import FadeIn from 'framers/FadeIn';

import { Container } from 'layout';
import Typography from 'common/Typography';
import Accordion from 'components/Accordion';

import { FaqSectionPropsType } from './model';

import './FaqSection.scss';

const FaqSection: FC<FaqSectionPropsType> = ({ items, heading, subheading }) => {
  const questionsSeparator = Math.ceil(items.length / 2);

  return (
    <Container fluid className="faq__wrapper">
      <Container gutter>
        <Typography
          color="sherwood-green"
          className="faq__heading"
          size={{ base: 25, md: 36 }}
          as="h2"
        >
          {heading}
        </Typography>
        {subheading ? (
          <Typography
            color="grey"
            className="faq__subheading"
            size={{ base: 16, md: 18 }}
            as="div"
            dangerouslySetInnerHTML={subheading}
          />
        ) : null}
        <div className="faq__questions-wrapper" data-testid="faq-section">
          <div>
            {[...items].splice(0, questionsSeparator).map(({ title, description, variant }) => (
              <FadeIn key={title}>
                <Accordion
                  visibleContent={title}
                  hiddenContent={description}
                  variant={variant}
                  data-testid="accordion-item"
                />
              </FadeIn>
            ))}
          </div>
          <div>
            {[...items]
              .splice(questionsSeparator, items.length)
              .map(({ title, description, variant }) => (
                <FadeIn key={title}>
                  <Accordion
                    visibleContent={title}
                    hiddenContent={description}
                    variant={variant}
                    data-testid="accordion-item"
                  />
                </FadeIn>
              ))}
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default FaqSection;
